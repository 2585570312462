<template>
    <inner-page-card heading="Refund Status">

        <div class="fl-x fl-j-c mb-4 w-100">
            <div>
                <s-form>
                    <div class="row">
                        <div class="ml-2 pt-2 d-sm-none col-12 col-lg-12">
                            <p class="font-inter-semi-bold">Filters</p>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <validated-date-picker style="height: 2.3rem!important;" type="date"
                                                   value-type="YYYY-MM-DD" v-model="from_date" :clearable="true"
                                                   placeholder="Date(From)" format="DD-MM-YYYY">
                            </validated-date-picker>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <validated-date-picker style="height: 2.3rem!important;" type="date"
                                                   value-type="YYYY-MM-DD" v-model="to_date" :clearable="true"
                                                   placeholder="Date(To)" format="DD-MM-YYYY"></validated-date-picker>
                        </div>
                        <div class="col-lg-3 ">
                            <validated-select v-model="status" class="c-input-select-b order-5 order-lg-4"
                                              placeholder="Status" :options="statusOptions"></validated-select>
                        </div>
                        <div class="col-lg-3 ">
                            <validated-input v-model="ext_search" class=" ml-lg-3  mr-lg-3 order-1 order-lg-5"
                                             placeholder="Search(Auction Id)">
                                <template #append>
                                    <i class="fa fa-search"></i>
                                </template>
                            </validated-input>
                        </div>
                    </div>
                </s-form>

            </div>
        </div>

        <custom-vue-table class="d-sm-none" :show-search-box="false" :fields="fields"
                          :url="refundAuctionList" :per-page="10" ref="table"
                          :extra-params="{ext_search, from_date, to_date, status}">

            <template #emd_amount="{rowData}">
                <span v-if="rowData.emd_amount" v-html="'₹ ' + rowData.emd_amount"></span>
                <span v-else></span>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <router-link :to="'/refund/' + rowData.auction_id + '/view-participants/'">
                        <btn color="secondary" text="View Participants"
                             :disabled="!(currentUser.permission.can_view_bidder || currentUser.is_superuser)"/>
                    </router-link>
                </div>
            </template>

        </custom-vue-table>

        <completed-auctions-card-mobile :from_date="from_date" :to_date="to_date"
                                        :status="status" :ext_search="ext_search" @view="setView"
                                        class="d-lg-none"></completed-auctions-card-mobile>

    </inner-page-card>
</template>

<script>
import CompletedAuctionsCardMobile from '../../views/refund/CompletedAuctionsCardMobile';
import urls from '../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'CompletedAuctionsPage',

    computed : {
        ...mapGetters(['currentUser'])
    },

    components : { CompletedAuctionsCardMobile },

    data () {
        return {
            from_date         : '',
            to_date           : '',
            status            : '',
            ext_search        : '',
            refundAuctionList : urls.auction.refund.refundList,

            loading : false,

            statusOptions : [
                { label : 'All', value : 'All' },
                { label : 'Initiated', value : 'Initiated' },
                { label : 'Completed', value : 'Completed' },
                { label : 'Failed', value : 'Failed' },
                { label : 'On Hold', value : 'On Hold' }
            ],

            fields : [
                {
                    name  : 'sl_no',
                    title : 'Sl No'
                },
                {
                    name  : 'auction_id',
                    title : 'Auction ID'
                },
                {
                    name  : 'property_category',
                    title : 'Property Type'
                },
                {
                    name  : 'auction_start_date',
                    title : 'Auction Date'
                },
                {
                    name  : '__slot:emd_amount',
                    title : 'EMD Amount'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ]
        };
    },
    methods : {
        setView (id) {
            this.$router.push('/refund/' + id + '/view-participants/');
        }
    }
};
</script>

<style scoped lang="scss">
.filter-grid {
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr) 4fr;
    grid-template-rows: 1fr;
}

.filter-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    @media(min-width: 992px) {
        //grid-template-columns: 1fr 3fr repeat(3, 2fr) 3fr 3fr;
        grid-template-columns: 1fr repeat(3, 2fr) 4fr;
        grid-template-rows: 1fr;
    }
}
</style>
