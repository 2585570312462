<template>
    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>
            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">
                <div class="gr-3-cols b-1 ">
                    <div class="fl-gr-1 b-1">
                        <div class="fl-x fl-j-c fl-a-c">
                            <div class="pos-r py-2">
                                <div class=""></div>
                                Sl No
                            </div>
                        </div>
                    </div>
                    <div class="fl-gr-1 b-1">
                        <div class="fl-x fl-j-s fl-j-c fl-a-c">
                            <div class="pos-r  py-2">
                                <div class=""></div>
                                Auction Id
                            </div>
                        </div>
                    </div>
                    <div class="fl-gr-1 b-1">
                        <div class="fl-x fl-j-c fl-a-c">
                            <div class="pos-r py-2">
                                <div class=""></div>
                                Property Type
                            </div>
                        </div>
                    </div>
                </div>
                <div class="b-1 bs-3">
                    <div class="gr-3-cols  font-inter-medium c-line-bottom">
                        <!--                <div class="line-bottom"></div>-->
                        <div class="b-1">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r py-1">
                                    <div class=""></div>
                                    {{ i.sl_no }}
                                </div>
                            </div>
                        </div>
                        <div class="b-1">
                            <div class="pos-r fl-x-bc">
                                <div class=""></div>
                                {{ i.auction_id }}
                            </div>
                        </div>
                        <div class="b-1">
                            <div class="pos-r fl-x-bc">
                                <div class=""></div>
                                {{ i.property_category }}
                            </div>
                        </div>
                    </div>
                    <div class="row b-1 fs--1  m-0">
                        <span class="b-1 w-100 col-12">
                            <div class="p-1">
                               <span class="font-inter-medium">Auction Date : </span> <span
                                class="ml-2">{{ i.auction_start_date }}</span>
                            </div>
                        </span>
                        <span class="b-1 w-100 col-12">
                            <div class="p-1">
                               <span class="font-inter-medium">EMD Amount : </span> <span
                                class="ml-2">{{ i.emd_amount }}</span>
                            </div>
                        </span>
                        <!--                        <span class="b-1 col-12">-->
                        <!--                            <div class="c-pos-a-c bg-blue-layer bottom&#45;&#45;2">-->
                        <!--                                <div class="text-white text-center fl-x-cc h-100">-->
                        <!--                                    Auction will end in <span>01h 37m 07s</span>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </span>-->
                        <span class="b-1 col-12">
                            <div class="p-1 fl-x fl-j-c py-3">
                                <div class="btn-group">
                                    <btn
                                        :disabled="!(currentUser.permission.can_view_bidder || currentUser.is_superuser)"
                                        @click="setView(i.auction_id)" text="View Participants" color="primary"></btn>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name : 'CompletedAuctionsCardMobile',

    props : {
        from_date : {
            type : String
        },

        to_date : {
            type : String
        },

        status : {
            type : String
        },

        ext_search : {
            type : String
        }
    },

    watch : {
        from_date () {
            this.loadData();
        },
        to_date () {
            this.loadData();
        },
        status () {
            this.loadData();
        },
        ext_search () {
            this.loadData();
        }
    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.auction.refund.refundList, {
                params : {
                    page       : that.page,
                    per_page   : that.per_page,
                    from_date  : that.from_date,
                    to_date    : that.to_date,
                    status     : that.status,
                    ext_search : that.ext_search
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        toggleUserActiveStatus (i) {
            this.items[i].active = !this.items[i].active;
        },

        setView (item) {
            this.$emit('view', item);
        }
    }
};
</script>

<style scoped>

</style>
